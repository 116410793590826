import axios from "axios";
import CONFIG from "../config";
import { extractMessageFromError, showError } from "../helpers";

const BASE_URL = CONFIG.API.BASE_URL;

export async function getOrderDetails(orderId) {
    try {
        const response = await axios.get(`${BASE_URL}/enrollments/?id=${orderId}`);
        const { data } = response;
        return data;
    } catch (error) {
        const message = extractMessageFromError(error);
        showError(message);
        return null;
    }
}
