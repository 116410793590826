import React, { useState, useEffect } from 'react';
import { Card, Table, Divider, Button, Spin, Space, Form, Switch } from 'antd';
import { getServices, createService, activateService, deactivateService } from '../../Services/ServiceService';
import ServiceModal from './ServiceModal';

const Services = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [servicesData, setServicesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedService, setSelectedService] = useState(null);
  const [form] = Form.useForm();

  document.title = "Services - Buzzer Admin";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const servicesResponse = await getServices();
        if (servicesResponse && servicesResponse.data) {
          const data = servicesResponse.data.map((obj) => {
            return { ...obj, "key": obj.id };
          });

          setServicesData(data);
        } else {
          console.error('Error fetching services data:', servicesResponse);
        }
      } catch (error) {
        console.error('Error fetching services:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const showModal = (service) => {
    setIsModalVisible(true);
    setSelectedService(service);
    if (service !== undefined) {
      form.setFieldsValue(service);
    } else {
      form.resetFields();
    }
  };

  const handleOk = async (values) => {
    setLoading(true);
    form.submit();
    try {
      if (selectedService) {
        // Handle view or inactive actions
        // You can add your logic here for view or inactive actions
      } else {
        // Create a new service
        var undefinedInValues = Object.values(form.getFieldsValue());
        if (undefinedInValues.includes(undefined)) {
          return;
        }

        const createServiceResponse = await createService(form.getFieldsValue());

        if (createServiceResponse && createServiceResponse.data) {
          const newService = createServiceResponse.data;
          newService.key = newService.id;
          setServicesData((prevData) => [...prevData, newService]);
        } else {
          console.error('Error creating service:', createServiceResponse);
        }
      }

      setIsModalVisible(false);
      setSelectedService(null); // Reset selectedService when closing the modal
    } catch (errorInfo) {
      console.error('Validation failed:', errorInfo);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedService(null); // Reset selectedService when closing the modal
  };

  const handleSwitchChange = async (checked, record) => {
    try {
      const updatedService = { ...record, is_active: checked };
      setLoading(true);

      // Use activateService and deactivateService based on the switch value
      if (checked) {
        await activateService(record.id);
      } else {
        await deactivateService(record.id);
      }

      const updateServiceResponse = true; // Assuming success for simplicity

      if (updateServiceResponse) {
        // Update the service in the state
        setServicesData((prevData) =>
          prevData.map((service) =>
            service.id === record.id ? { ...service, is_active: checked } : service
          )
        );
      } else {
        console.error('Error updating service:', updateServiceResponse);
      }
    } catch (error) {
      console.error('Error updating service:', error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: 'Service Name',
      dataIndex: 'service_name',
      key: 'service_name',
    },
    {
      title: 'Description',
      dataIndex: 'service_description',
      key: 'service_description',
    },
    {
      title: 'Cost',
      dataIndex: 'service_cost',
      key: 'service_cost',
      render: (cost) => `₹${cost.toFixed(2)}`,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button type="primary" onClick={() => showModal(record)}>
            View
          </Button>
          <Switch
            checkedChildren="Active"
            unCheckedChildren="Inactive"
            checked={record.is_active}
            onChange={(checked) => handleSwitchChange(checked, record)}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Spin
        spinning={loading}
        size="large"
        tip="Loading Services..."
        style={{ width: '100%', textAlign: 'center' }}
      >
        <Card
          title={
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              Our Services
              <Button type="primary" onClick={() => showModal()}>
                Add Service
              </Button>
            </div>
          }
          style={{ width: '100%', margin: 'auto' }}
        >
          <Divider />

          <Table dataSource={servicesData} columns={columns} pagination={false} />

          {/* Modal for Add/View Service */}
          <ServiceModal
            visible={isModalVisible}
            onCancel={handleCancel}
            onOk={handleOk}
            initialValues={selectedService}
            form={form}
            loading={loading}
          />
        </Card>
      </Spin>
    </div>
  );
};

export default Services;
