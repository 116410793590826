// businessService.js

import axios from 'axios';
import CONFIG from "../config";
import { extractMessageFromError, showError } from "../helpers";

const BASE_URL = CONFIG.API.BASE_URL;

export const updateBusinessSettings = async (settingsData) => {
  try {
    const response = await axios.put(`${BASE_URL}/businesses/settings`, settingsData);
    return response.data;
  } catch (error) {
    showError(extractMessageFromError(error));
  }
};


export const getBusinessSettings = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/businesses/settings`);
      return response.data;
    } catch (error) {
      showError(extractMessageFromError(error));
      throw error;
    }
  };