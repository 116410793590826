function Copyright() { 
    return <div style={{
        width: "100%",
        padding: "5px 20px",
        backgroundColor: "#e2e2e2",
        color: "black",
        position: "absolute",
        bottom: "0",
        textAlign: "center"
    }}>
        Copyright &copy; 2024 - Beeclue Technologies
    </div>
}

export default Copyright;