import React, { useState, useEffect } from 'react';
import { Form, Select, Input, Button, Row, Col, Typography, message, Card } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { getServices } from '../../Services/ServiceService';
import { postOrder } from '../../Services/CustomerService';

const { Option } = Select;
const { Text } = Typography;

const CreateOrder = ({success, failure}) => {
  const navigate = useNavigate();
  const { id: customerId } = useParams();

  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});
  const [form] = Form.useForm();

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    setLoading(true);
    const response = await getServices();

    if (response && response.data) {
      setServices(response.data);
    }

    setLoading(false);
  };

  const onServiceChange = (value) => {
    const selectedService = services.find((service) => service.id === value);
    setValues({
      ...values,
      service: value,
      subtotal: selectedService ? selectedService.service_cost : 0,
    });
  };

  const onFinish = async (values) => {
    try {
        const selectedService = services.find((service) => service.id === values.service);

        const data = {
          serviceId: values.service,
          total: calculateTotal(),
          subtotal: selectedService ? selectedService.service_cost : 0,
          tax: values.tax || 0,
          discount: values.discount || 0,
        };
    
        const orderData = {
          customerId: parseInt(customerId),
        ...data
        };
    
        console.log(orderData);
    

      // Call your function to post the order
      const orderResponse = postOrder(orderData);

      if (orderResponse) {
        message.success('Order created successfully');
        // Redirect to the customer details page after creating the order
        success();
      } 
    } catch (error) {
      console.error('Error creating order:', error);
    }
  };

  const calculateTotal = () => {
    const subtotal = values?.subtotal || 0;
    const tax = values?.tax || 0;
    const discount = values?.discount || 0;

    return (subtotal * (1 + tax / 100) * (1 - discount / 100)).toFixed(2);
  };

  return (
    <div>
      <Card title="Create Order">
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          initialValues={{
            tax: 0,
            discount: 0,
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="service"
                label="Select Service"
                rules={[{ required: true, message: 'Please select a service' }]}
              >
                <Select showSearch loading={loading} placeholder="Select a service" onChange={onServiceChange}>
                  {services.map((service) => (
                    <Option key={service.id} value={service.id}>
                      {service.service_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Text strong>Service Details</Text>
              <div>
                <Text>Subtotal</Text>
                <Input
                  disabled
                  addonAfter="USD"
                  value={values?.subtotal || 0}
                />
              </div>
              <div>
                <Text>Tax</Text>
                <Form.Item name="tax" noStyle>
                  <Input
                    addonAfter="%"
                    onChange={(e) => {
                      setValues({ ...values, tax: e.target.value });
                    }}
                  />
                </Form.Item>
              </div>
              <div>
                <Text>Discount</Text>
                <Form.Item name="discount" noStyle>
                  <Input
                    addonAfter="%"
                    onChange={(e) => {
                      setValues({ ...values, discount: e.target.value });
                    }}
                  />
                </Form.Item>
              </div>
              <div>
                <Text>Total</Text>
                <Input disabled addonAfter="USD" value={calculateTotal()} />
              </div>
            </Col>
          </Row>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Create Order
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default CreateOrder;
