import axios from "axios";
import CONFIG from "../config";
import { extractMessageFromError, showError } from "../helpers";
import toast from "react-hot-toast";

const BASE_URL = CONFIG.API.BASE_URL;

export async function login(business_id, email, password) {
    const data = { 
        "business_id": business_id, 
        "email": email, 
        "password": password
    };

    try { 
        const response = await axios.post(`${BASE_URL}/businesses/users/signin/`, data);
        const res = response.data; 
        if(res.message) {
            showError(res.message);
            return null;
        } else { 
            localStorage.setItem("token", res.token);
            localStorage.setItem("business", JSON.stringify(res.business));
            axios.defaults.headers.common["Authorization"] = res.token;
            return res
        }
    } catch (error) {

        var message = extractMessageFromError(error);
       showError(message);
    }
}


