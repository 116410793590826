import React from 'react';
import moment from 'moment';
import { getGravatarUrl } from '../helpers';
import { Col, Row } from 'antd';

const MessageView = ({ messages, customer }) => {
    const businessData = JSON.parse(localStorage.getItem("business"));
    const userData = businessData.user;
    const userGravatarURL = "";

    return (
        <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
            {messages.map((message, index) => (
                <div style={{ border: "0.5px solid #e2e2e2", padding: "15px", marginTop: "10px", borderRadius: "5px" }}>
                    <Row>
                        <Col span={3} style={{ padding: "10px", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                            <img src={getGravatarUrl(message.sender === "user" ? userData.email : customer.email)} width="50px" height="50px" style={{ borderRadius: "25px" }} />
                            <p style={{ marginTop: "5px", marginBottom: "0", fontSize: "16px", textAlign: "center" }}>
                                {message.sender === "user" ? userData.name : customer.fullname} <br /> <small style={{fontSize: "10px"}}>By {businessData.name}</small>
                            </p>
                            <p style={{ marginTop: "5px", marginBottom: "0", fontSize: "12px", color: "gray", textAlign: "center" }}>
                                {moment(message.createdAt).format('MMMM D, YYYY h:mm A')}
                            </p>
                        </Col>

                        <Col span={21} style={{ padding: "10px" }} >
                            {message.text}
                        </Col>
                    </Row>
                </div>
            ))}
        </div>
    );
};

export default MessageView;
