import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, Divider, Spin, Table, Input, Button } from 'antd';
import moment from 'moment';
import { getOrderDetails } from '../../Services/OrderService';
import MessageView from '../../Components/MessageView';
import { SendOutlined } from '@ant-design/icons';
import { postMessage } from '../../Services/MessageService';

const { TextArea } = Input;

const OrderDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [messageInput, setMessageInput] = useState('');
  const [sendingMessage, setSendingMessage] = useState(false);
  const [redirectToHome, setRedirectToHome] = useState(false); // State for redirection
  const [reloadComponent, setReloadComponent] = useState(false); // State to trigger component reload

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await getOrderDetails(id);
        if (response.enrollment) {
          setOrder(response.enrollment);
        } else {
          // If no enrollment found, set redirectToHome to true
          setRedirectToHome(true);
        }
      } catch (error) {
        console.error('Error fetching order details:', error);
        // If API call fails, set redirectToHome to true
        setRedirectToHome(true);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [id, reloadComponent]); // Add reloadComponent to dependency array

  const handleSendMessage = async () => {
    try {
      setSendingMessage(true); // Start loading
      await postMessage(messageInput, id); // Call postMessage with text and order ID
      setMessageInput(''); // Clear the message input after sending
      setReloadComponent(!reloadComponent); // Toggle reloadComponent to trigger component reload
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setSendingMessage(false); // Stop loading
    }
  };

  if (redirectToHome) {
    // If redirectToHome is true, redirect to home page
    navigate('/');
  }

  let columns = [];
  let dataSource = [];
  if (order) {
    dataSource = [{ ...order }];
    dataSource[0].service_opted = order.service.service_name;
    delete dataSource[0].service;
    delete dataSource[0].messages;
    delete dataSource[0].customer;
    columns = Object.keys(dataSource[0]).map((key) => ({
      title: (key === 'createdAt' ? 'Order Date' : key.replace(/_/g, '  ')).toUpperCase(),
      dataIndex: key,
      key: key,
      render: (data) => {
        if (key === 'total' || key === 'subtotal' || key === 'tax' || key === 'discount') {
          return <>{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(data)}</>;
        } else if (key === 'createdAt') {
          return moment(data).format('MMMM D, YYYY');
        } else if (key === 'payment_status') {
          return (
            <span style={{ textTransform: 'capitalize' }}>
              {data === 'pending' ? 'Pending' : 'Completed'}
            </span>
          );
        } else {
          return data;
        }
      },
    }));
  }

  return (
    <div>
      <Spin spinning={loading} size='large' tip='Order Details Loading' fullscreen={false} />
      {order ? (
        <Card title='Order Details' style={{ width: '100%', margin: 'auto' }}>
          <Table columns={columns} dataSource={dataSource} pagination={false} />
          <Divider />
          <Card title='Messages' style={{ marginTop: '20px' }}>
            {/* Render MessageView component with messages */}
            <TextArea
              rows={4}
              value={messageInput}
              onChange={(e) => setMessageInput(e.target.value)}
              placeholder='Type your message here...'
              style={{ marginBottom: '10px' }}
              disabled={sendingMessage} // Disable textarea while sending message
            />
            {/* Send button */}
            <Button
              type='primary'
              onClick={handleSendMessage}
              icon={<SendOutlined />}
              loading={sendingMessage} // Show loading icon if sending message
              disabled={sendingMessage} // Disable button while sending message
            >
              Send
            </Button>
            <br/>
            <MessageView messages={order.messages} />
            {/* Text area for typing message */}
            
          </Card>
        </Card>
      ) : (
        <p>Loading Order Details</p>
      )}
    </div>
  );
};

export default OrderDetails;
