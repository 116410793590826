import axios from "axios";
import CONFIG from "../config";
import { extractMessageFromError, showError } from "../helpers";

const BASE_URL = CONFIG.API.BASE_URL;

export async function getCustomers() { 
    try { 
        const response = await axios.get(`${BASE_URL}/customers/`);
        const res = response.data;
        if(res.data) {
            return res;
           
        } else { 
            showError(res.message);
            return null;
        }
        
    } catch (error) { 
        var message = extractMessageFromError(error);
        showError(message);
        return null;
    }
}


export async function getCustomer(id) {
    try { 
        const response = await axios.get(`${BASE_URL}/customers/${id}`);
        const res = response.data;
        if(res.data) {
            return res; 
        } else { 
            showError(res.message);
            return null;
        }
    } catch (error) {
        var message = extractMessageFromError(error);
        showError(message);
        return null;
    }
 }

 export async function postCustomer(customerData) {
  try {
    const response = await axios.post(`${BASE_URL}/customers/`, customerData);
    const res = response.data;
    if (res.data) {
      return res;
    } else {
      showError(res.message);
      return null;
    }
  } catch (error) {
    var message = extractMessageFromError(error);
    showError(message);
    return null;
  }
}

export async function postOrder(orderData) {
    try {
      const response = await axios.post(`${BASE_URL}/enrollments/`, orderData);
      const res = response.data;
      if (res.data) {
        return res;
      } else {
        showError(res.message);
        return null;
      }
    } catch (error) {
      var message = extractMessageFromError(error);
      showError(message);
      return null;
    }
  }