import React, { useState, useEffect } from 'react';
import { Card, Table, Button, message, Spin, Input } from 'antd';
import { getCustomers, postCustomer } from '../../Services/CustomerService';
import { PlusOutlined } from '@ant-design/icons';
import AddCustomerForm from './AddCustomerForm';
import { Link } from 'react-router-dom';

const Customers = () => {
  const [allCustomers, setAllCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [addingCustomer, setAddingCustomer] = useState(false);
  const [addCustomerLoading, setAddCustomerLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchCustomers();
  }, []); // Fetch customers on initial mount

  const fetchCustomers = async () => {
    try {
      setLoading(true);
      const response = await getCustomers();

      if (response && response.data) {
        const data = response.data.map((customer) => ({
          ...customer,
          name: customer.fullname,
          key: customer.id,
        }));

        setAllCustomers(data);
        filterCustomers(searchTerm, data);
      } else {
        console.error('Error fetching customers data:', response);
      }
    } catch (error) {
      console.error('Error fetching customers:', error);
    } finally {
      setLoading(false);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCreate = async (values) => {
    try {
      setAddingCustomer(true);
      setAddCustomerLoading(true);
      console.log(values);

      const response = await postCustomer(values);

      if (response && response.data) {
        message.success('Customer added successfully');
        setIsModalVisible(false);

        // Add the new customer to the existing customers
        const newCustomer = {
          ...response.data,
          name: response.data.fullname,
          key: response.data.id,
        };
        setAllCustomers((prevCustomers) => [...prevCustomers, newCustomer]);
       
        // Filter customers based on the search term
        filterCustomers(searchTerm, [...allCustomers, newCustomer]);
      } else {
        console.error('Error creating customer:', response);
      }
    } catch (error) {
      console.error('Error creating customer:', error);
    } finally {
      setAddingCustomer(false);
      setAddCustomerLoading(false);
    }
  };

  const filterCustomers = (term, data) => {
    const filtered = data.filter((customer) =>
      customer.name.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredCustomers(filtered);
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    filterCustomers(value, allCustomers);
  };

  const tableColumns = [
    {
      title: 'Customer ID',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: 'Name',
      key: 'name',
      render: (_, customer) => {
        return <Link to={`/customers/${customer.id}`}>{customer.name}</Link>;
      },
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, customer) => (
        <Button type="link" href={`mailto:${customer.email}`}>
          Email
        </Button>
      ),
    },
  ];

  return (
    <Spin tip="Loading Customers" spinning={loading} size="large">
      <Card
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div>
              Customers
              <Input
                placeholder="Search customer"
                style={{ marginLeft: 16, width: 200 }}
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
              />
            </div>
            <Button type="primary" onClick={showModal} icon={<PlusOutlined />} disabled={addingCustomer}>
              Add Customer
            </Button>
          </div>
        }
      >
        <AddCustomerForm visible={isModalVisible} onCreate={handleCreate} onCancel={handleCancel} loading={addCustomerLoading} />
        {filteredCustomers.length === 0 ? (
          <p>No Customers Found.</p>
        ) : (
          <Table columns={tableColumns} dataSource={filteredCustomers} />
        )}
      </Card>
    </Spin>
  );
};

export default Customers;
