import React from 'react';
import { Result, Button } from 'antd';
import './DesktopOnlyMessage.css'; // Create a CSS file for styling

function DesktopOnlyMessage() {
  const handleSwitchToDesktop = () => {
    // You can add logic to redirect or display additional instructions
    alert('Switch to the desktop version to continue using the app.');
  };

  return (
    <div className="desktop-only-container">
      <Result
        status="403"
        title="This app is available for desktop only."
        subTitle="Try opening this app on desktop computer."
        extra={
          <Button type="primary" onClick={handleSwitchToDesktop}>
            Switch to Desktop
          </Button>
        }
      />
    </div>
  );
};

export default DesktopOnlyMessage;
