import React from 'react';
import { Layout, Menu } from 'antd';
import { ApartmentOutlined, DashboardOutlined, UploadOutlined, UserOutlined, SettingOutlined, LogoutOutlined } from '@ant-design/icons';
import { Navigate, useNavigate } from 'react-router-dom';
import './DashboardNavbar.css';

const { Sider } = Layout;

function DashboardSidebar({ collapsed, handleLogout }) {
    const navigate = useNavigate();
    return (
        <Sider trigger={null} collapsible collapsed={collapsed} theme='dark'>
            <div className="demo-logo-vertical" />
            <div className="logo-container">
                <img src='https://res.cloudinary.com/dyy4lw5xp/image/upload/v1711243181/hive-white_stuzhi.png' className='logo-image' />
            </div>
            <Menu theme="dark" mode="inline" defaultSelectedKeys={['dashboard']} className="menu-items">
                <Menu.Item key="dashboard" icon={<DashboardOutlined />} onClick={() => navigate("/")}>
                    Dashboard
                </Menu.Item>
                <Menu.Item key="customers" icon={<UserOutlined />} onClick={() => navigate("/customers")}>
                    Customers
                </Menu.Item>
                <Menu.Item key="services" icon={<ApartmentOutlined />} onClick={() => navigate("/services")}>
                    Services
                </Menu.Item>
                <Menu.Item key="orders" icon={<UploadOutlined />} onClick={() => navigate("/orders")}>
                    Orders
                </Menu.Item>
                <Menu.Item key="settings" icon={<SettingOutlined />} onClick={() => navigate("/settings")}>
                    Settings
                </Menu.Item>
            </Menu>
            {/* Logout Menu Item */}
            <Menu theme="dark" mode="inline" className="logout-menu">
                <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
                    Logout
                </Menu.Item>
            </Menu>
        </Sider>
    );
}

export default DashboardSidebar;
