import React from 'react';
import { Modal, Form, Input, Switch } from 'antd';

const AddCustomerForm = ({ visible, onCreate, onCancel, loading }) => {
    const [form] = Form.useForm();

    return (
        <Modal
            open={visible}
            confirmLoading={loading}
            title="Add Customer"
            okText="Add Customer"
            cancelText="Cancel"
            onCancel={onCancel}
            onOk={() => {
                form.validateFields()
                    .then(values => {
                        form.resetFields();
                        onCreate(values);
                    })
                    .catch(errorInfo => {
                        console.error('Validation failed:', errorInfo);
                    });
            }}
        >
            <Form form={form} layout="vertical">
                <Form.Item name="fullname" label="Full Name" rules={[{ required: true, message: 'Please enter the customer\'s full name' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email', message: 'Please enter a valid email address' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="contact" label="Contact" rules={[{ required: true, message: 'Please enter the contact number' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="is_active" label="Is Active" valuePropName="checked">
                    <Switch />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddCustomerForm;
