import toast from "react-hot-toast";

export function extractMessageFromError(error) {
    return error.response.data.message ?? "Some Error Occoured"
}

export function showError(errorString) {
    toast.error(errorString, { 
        position: "top-right"
    });
}

export function showSuccess(successString) {
    toast.success(successString, { 
        position: "top-right"
    })
}

export function showMessage(messageString) {
    toast(messageString, { 
        position: "top-right"
    })
}

export function getGravatarUrl(email, size = 80) {
    // Lowercase the email
    const lowerCasedEmail = email.toLowerCase().trim();
  
    // Simplified MD5-like hash (not cryptographically secure)
    let hash = 0;
    for (let i = 0; i < lowerCasedEmail.length; i++) {
      const charCode = lowerCasedEmail.charCodeAt(i);
      hash = ((hash << 5) - hash) + charCode;
      hash &= hash; // Convert to 32-bit unsigned integer
    }
  
    // Convert hash to hexadecimal string
    const hexHash = hash.toString(16).padStart(32, "0");
  
    // Build the Gravatar URL
    return `https://secure.gravatar.com/avatar/${hexHash}?s=${size}&d=mm`;
  }