import axios from 'axios';
import CONFIG from '../config';
import { extractMessageFromError, showError } from '../helpers';

const BASE_URL = CONFIG.API.BASE_URL;

export async function postMessage(text, order_id) {
  try {
    const businessData = JSON.parse(localStorage.getItem("business"));
    const userId = businessData.user.id; 
    const response = await axios.post(`${BASE_URL}/enrollments/messages/`, { text: text, order_id: order_id, user_id: userId});
    const res = response.data;
    if (res.data) {
      return res;
    } else {
      showError(res.message);
      return null;
    }
  } catch (error) {
    var message = extractMessageFromError(error);
    showError(message);
    return null;
  }
}

