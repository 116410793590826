import React, { useEffect, useState } from 'react';
import { Card, Descriptions, Divider, Spin, Button, Drawer, Table, Tag, Input, Select } from 'antd';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { getCustomer } from '../../Services/CustomerService';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import CreateOrder from '../Customers/CreateOrder'; // Import the CreateOrder component
import moment from 'moment';

const { Search } = Input;
const { Option } = Select;

const CustomerDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [customer, setCustomer] = useState(null);
  const [spinning, setSpinning] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false); // State to control the visibility of the drawer
  const [enrollmentSearchTerm, setEnrollmentSearchTerm] = useState('');
  const [filteredEnrollments, setFilteredEnrollments] = useState([]);
  const [orderStatusFilter, setOrderStatusFilter] = useState(null);
  document.title = "Customer Details - Buzzer Admin";

  useEffect(() => {
    if (id === null) {
      navigate("/");
    } else {
      fetchAndGetCustomers();
    }
  }, []);

  const fetchAndGetCustomers = () => {
    setSpinning(true);
    getCustomer(id).then((customerData) => {
      setCustomer(customerData.data.customer);
      setFilteredEnrollments(customerData.data.customer.enrollments);
    }).catch((error) => {
      // handle error
    }).finally(() => {
      setSpinning(false);
    });
  }

  const handleCreateOrder = () => {
    // Open the drawer when creating an order
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    // Close the drawer
    setDrawerVisible(false);
  };

  const handleEnrollmentSearch = (value) => {
    setEnrollmentSearchTerm(value);
    filterEnrollments(value, orderStatusFilter);
  };

  const handleOrderStatusFilter = (value) => {
    setOrderStatusFilter(value);
    filterEnrollments(enrollmentSearchTerm, value);
  };

  const filterEnrollments = (term, status) => {
    let filtered = customer.enrollments;

    // Filter by order ID
    if (term) {
      filtered = filtered.filter((enrollment) =>
        enrollment.order_id.toString().includes(term)
      );
    }

    // Filter by order status
    if (status) {
      filtered = filtered.filter((enrollment) =>
        enrollment.payment_status === status
      );
    }

    setFilteredEnrollments(filtered);
  };

  const columns = [
    {
      title: 'Order ID',
      dataIndex: 'order_id',
      key: 'order_id',
    },
    { 
      title: "Service Opted",
      dataIndex: "service",
      key: "service",
      render: (service) => (
        service ? service.service_name : 'N/A'
      ),
    },
    {
      title: 'Payment Status',
      dataIndex: 'payment_status',
      key: 'payment_status',
      render: (status) => (
        <Tag color={status === 'pending' ? 'orange' : 'green'}>{status.toUpperCase()}</Tag>
      ),
    },
    {
      title: 'Total (INR)',
      dataIndex: 'total',
      key: 'total',
      render: (total) => (
        new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(total)
      ),
    },
    {
      title: 'Order Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => (
        moment(createdAt).format('DD-MMM-YYYY HH:mm:ss')
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Link to={`/order/${record.order_id}`}>View Details</Link>
      ),
    },
  ];

  return (
    <div>
      <Spin spinning={spinning} size='large' tip="Customer Details Loading" fullscreen={false} />
      {customer == null ? <p>Loading Customer Details</p> :
        <Card
          title="Customer Details"
          extra={
            <Button
              type="primary"
              onClick={handleCreateOrder}
              style={{ float: 'right', marginRight: 8 }}
              icon={<PlusOutlined />}
            >
              Create Order for {customer.fullname}
            </Button>
          }
          style={{ width: '100%', margin: 'auto' }}
        >
          <Descriptions bordered column={1}>
            <Descriptions.Item label="Full Name">{customer.fullname}</Descriptions.Item>
            <Descriptions.Item label="Email">
              <a href={`mailto:${customer.email}`}>{customer.email}</a>
            </Descriptions.Item>
            <Descriptions.Item label="Contact">
              <a href={`tel:${customer.contact}`}>{customer.contact}</a>
            </Descriptions.Item>
            <Descriptions.Item label="Status">
              {customer.is_active ? 'Active' : 'Inactive'}
            </Descriptions.Item>

            {/* Address as another Descriptions.Item */}
            <Descriptions.Item label="Address" span={2}>
              {customer.addresses.map((address) => (
                <div key={address.id}>
                  <p>{address.address}</p>
                  <p>{`${address.city}, ${address.province} ${address.postal_code}`}</p>
                </div>
              ))}
            </Descriptions.Item>
          </Descriptions>

          <Divider />
          <Card
            title={
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                  Enrollments
                </div>
                <div>
                  <Search
                    placeholder="Search by Order ID"
                    allowClear
                    onSearch={handleEnrollmentSearch}
                    style={{ marginRight: 8, width: 200 }}
                  />
                  <Select
                    placeholder="Filter by Status"
                    style={{ width: 120 }}
                    onChange={handleOrderStatusFilter}
                  >
                    <Option value="pending">Pending</Option>
                    <Option value="completed">Completed</Option>
                  </Select>
                </div>
              </div>
            }
          >
            <Table dataSource={filteredEnrollments} columns={columns} />
          </Card>
        </Card>
      }

      {/* Drawer for creating orders */}
      <Drawer
        title={`Create Order for ${customer?.fullname}`}
        placement="bottom"
        onClose={closeDrawer}
        open={drawerVisible}
        height={800} // Set the desired height
        destroyOnClose={true}
      >
        {/* CreateOrder component rendered in the drawer */}
        <CreateOrder customerId={id} onCloseDrawer={closeDrawer} success={() => {
          setDrawerVisible(false);
          fetchAndGetCustomers();
        }} />
      </Drawer>
    </div>
  );
};

export default CustomerDetails;
