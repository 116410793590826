import React, { useState } from 'react';
import { Row, Col, Form, Input, Button, Typography, Spin } from 'antd';
import './Login.css'; // You can create this CSS file for styling
import Copyright from '../../Components/Copyright';
import { login } from '../../Services/LoginService';

const Login =  ({setToken}) =>  {

    const [spin, setSpin] = useState(false);
    const  onFinish = (values) => {
        console.log("Hello");
        // Handle your login logic here
        setSpin(true);
        login(values["businessId"], values["email"], values["password"] ).then((response)=>{
            if(response.token) {
                setToken(response.token)
            } else { 

            }
            
        }).catch((error)=>{
            // Error
        }).finally(()=>{
            setSpin(false);
        });
        

    };

    return (
        <Spin size='large' tip="Logging in" spinning={spin}>

        
        <div className='login-container'>
            <Row>
                <Col span={14} className='image-background'></Col>
                <Col span={10} >
                    {/* Login Form Started */}
                    <div className="form-container">
                    <img src='https://res.cloudinary.com/dyy4lw5xp/image/upload/v1711243149/hive-orange_xuw9gp.png' width="80%" alt='hive logo' />
                    <h1 style={{ margin: 0, padding: 0}}>Welcome to Hive</h1>
                    <p>By <a href="https://beeclue.com">Beeclue</a></p> <br/>
                        <Typography.Paragraph>
                            Login with your business credentials. If you do not have credentials, please reach out to your tech manager. 
                           
                        </Typography.Paragraph>
                        <Form
                            name="login-form"
                            onFinish={onFinish}
                            initialValues={{ remember: true }}
                        >
                            <Form.Item
                                name="businessId"
                                rules={[{ required: true, message: 'Please enter your Business ID!' }]}
                                style={{width:"100%"}}
                            >
                                <Input placeholder="Business ID"/>
                            </Form.Item>

                            <Form.Item
                                name="email"
                                rules={[{ required: true, message: 'Please enter your Email!' }]}
                            >
                                <Input placeholder="Email" />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: 'Please enter your Password!' }]}
                            >
                                <Input.Password placeholder="Password" />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    Log in
                                </Button>
                            </Form.Item>
                        </Form>
                        <Typography.Paragraph>
                        If you have lost your username/password please contact us at <a href='mailto:support@beeclue.com'>support@beeclue.com</a>
                        </Typography.Paragraph>
                    </div>
                    {/* Login Form Finished */}
                    <Copyright />
                </Col>
            </Row>
        </div>
        </Spin>
    );
};

export default Login;
