import React, { useState } from 'react';
import { Layout, theme } from 'antd';
import DashboardSidebar from '../../Components/DashboardSidebar';
import { Outlet } from 'react-router';
import DashboardNavbar from '../../Components/DashboardNavbar';


const { Content } = Layout;

const Dashboard = ({handleLogout}) => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  document.title = "Dashboard - Buzzer Admin";

  return (
    <Layout>
      <DashboardSidebar collapsed={collapsed} handleLogout={handleLogout}/>
      <Layout>
        <DashboardNavbar collapsed={collapsed} setCollapsed={setCollapsed} colorBgContainer={colorBgContainer} />
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: "90vh",
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}

        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default Dashboard;