import React from 'react';
import { Modal, Form, Input, InputNumber, Button } from 'antd';

const ServiceModal = ({ visible, onCancel, onOk, initialValues, form, loading }) => {
  return (
    <Modal
      title={initialValues ? 'View Service' : 'Add Service'}
      open ={visible}
      onOk={onOk}
      onCancel={onCancel}
      forceRender
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="ok" type="primary" loading={loading} onClick={onOk}>
          {initialValues ? 'Save' : 'Add'}
        </Button>,
      ]}
    >
      <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} initialValues={initialValues}>
        <Form.Item label="Service Name" name="service_name" rules={[{ required: true, message: 'Please enter the Service Name' }]}>
          <Input disabled={!!initialValues} />
        </Form.Item>
        <Form.Item label="Description" name="service_description" rules={[{ required: true, message: 'Please enter the Service Description' }]}>
          <Input.TextArea disabled={!!initialValues} />
        </Form.Item>
        <Form.Item label="Cost" name="service_cost" rules={[{ required: true, message: 'Please enter the Service Cost' }]}>
          <InputNumber
            style={{ width: '100%' }}
            formatter={value => `₹${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/[₹\s,]/g, '')}
            disabled={!!initialValues}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ServiceModal;
