import React, { useState, useEffect } from 'react';
import { Card, Form, Input, Button, message } from 'antd';
import { updateBusinessSettings, getBusinessSettings } from '../../Services/BusinessService';

const Settings = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  document.title = "Business Settings - Buzzer Admin";

  useEffect(() => {
    const fetchBusinessSettings = async () => {
      try {
        setLoading(true);
        
        // Fetch business settings using the service
        const settingsResponse = await getBusinessSettings();

        if (settingsResponse && settingsResponse.data) {
          // Prepopulate form fields with business_settings data
          form.setFieldsValue(settingsResponse.data);
        }
      } catch (error) {
        console.error('Error fetching business settings:', error);
        message.error('An error occurred while fetching settings');
      } finally {
        setLoading(false);
      }
    };

    // Fetch existing settings data and set form fields if needed
    fetchBusinessSettings(); // Call the function to fetch and prepopulate

  }, [form]);

  const onFinish = async (values) => {
    try {
        setLoading(true);
       
        // Call the service to update the business settings
        await updateBusinessSettings(values);
  
        message.success('Settings updated successfully');
      } catch (error) {
        console.error('Error updating settings:', error);
        message.error('An error occurred while updating settings');
      } finally {
        setLoading(false);
      }
  };

  return (
    <Card title="Business Settings" style={{ width: '100%', margin: 'auto' }}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        {/* Paypal Settings Section */}
        <Form.Item label="Paypal Client ID" name="paypal_client_id" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
          <Input />
        </Form.Item>
        <Form.Item label="Paypal Secret" name="paypal_secret" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
          <Input />
        </Form.Item>

        {/* SendGrid Settings Section */}
        <Form.Item label="SendGrid API Key" name="sendgrid_api_key" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
          <Input />
        </Form.Item>

        {/* Mailchimp Settings Section */}
        <Form.Item label="Mailchimp API Key" name="mailchimp_api_key" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
          <Input />
        </Form.Item>

        {/* Add more form fields for other settings as needed */}

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Save Settings
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Settings;
