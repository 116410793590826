import axios from "axios";
import CONFIG from "../config";
import { extractMessageFromError, showError } from "../helpers";

const BASE_URL = CONFIG.API.BASE_URL;

export async function getServices() {
  try {
    const response = await axios.get(`${BASE_URL}/service/all`);
    const res = response.data;
    if (res.data) {
      return res;
    } else {
      showError(res.message);
      return null;
    }
  } catch (error) {
    var message = extractMessageFromError(error);
    showError(message);
    return null;
  }
}

export async function getService(serviceId) {
  try {
    const response = await axios.get(`${BASE_URL}/service/${serviceId}`);
    const res = response.data;
    if (res.data) {
      return res;
    } else {
      showError(res.message);
      return null;
    }
  } catch (error) {
    var message = extractMessageFromError(error);
    showError(message);
    return null;
  }
}

export async function createService(newServiceData) {
  try {
    const response = await axios.post(`${BASE_URL}/service/add/`, newServiceData);
    const res = response.data;
    if (res.data) {
      return res;
    } else {
      showError(res.message);
      return null;
    }
  } catch (error) {
    var message = extractMessageFromError(error);
    showError(message);
    return null;
  }
}

export async function activateService(serviceId) {
  try {
    const response = await axios.patch(`${BASE_URL}/service/${serviceId}/activate`);
    const res = response.data;
    if (res.data) {
      return res;
    } else {
      showError(res.message);
      return null;
    }
  } catch (error) {
    var message = extractMessageFromError(error);
    showError(message);
    return null;
  }
}

export async function deactivateService(serviceId) {
  try {
    const response = await axios.patch(`${BASE_URL}/service/${serviceId}/deactivate`);
    const res = response.data;
    if (res.data) {
      return res;
    } else {
      showError(res.message);
      return null;
    }
  } catch (error) {
    var message = extractMessageFromError(error);
    showError(message);
    return null;
  }
}

