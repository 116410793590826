import './App.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Login from './Pages/Login/Login';
import Dashboard from './Pages/Dashboard/Dashboard';
import { useState } from 'react';
import NotFoundPage from './Pages/404/404';
import Customers from './Pages/Customers/Customer';
import axios from 'axios';
import DesktopOnlyMessage from './Pages/404/DesktopOnlyMessage';
import CustomerDetails from './Pages/Customers/CustomerDetails';
import Services from './Pages/Services/Services';
import Settings from './Pages/Settings/Settings';
import CreateOrder from './Pages/Customers/CreateOrder';
import { ConfigProvider } from 'antd';
import OrderDetails from './Pages/Customers/OrderDetail';



// Add Token in Axios Headers
if (localStorage.getItem("token")) {
  axios.defaults.headers.common["Authorization"] = localStorage.getItem("token");
}
// Add Token in Axios Headers - Finished

function AppProvider() {
  const [token, setToken] = useState();
  const tokenFromStorage = localStorage.getItem("token");

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Dashboard handleLogout={() => {
        setToken(null);
        localStorage.removeItem("token");
        localStorage.removeItem("business");
      }} />,
      errorElement: <NotFoundPage />,
      children: [
        {
          path: "customers",
          element: <Customers />,
        },
        {
          path: "customers/:id/",
          element: <CustomerDetails />
        },
        {
          path: "create-order/:id/",
          element: <CreateOrder />
        },
        {
          path: "services",
          element: <Services />
        },
        {
          path: "settings",
          element: <Settings />
        },
        {
          path: "order/:id/",
          element: <OrderDetails />
        }
      ]
    }
  ])

  // To check if app is being used by mobile. 
  const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  };
  if (isMobileDevice()) {
    return <DesktopOnlyMessage />
  }

  // If token is not available, return to login. 
  if (!tokenFromStorage) {
    return <Login setToken={setToken} />
  }


  return (
    <RouterProvider router={router}>

    </RouterProvider>
  );
}
function App() {
  document.title = "Hive App - Beeclue Technologies";

  return <ConfigProvider theme={{
    "token": {
      "colorPrimary": "#ac4123",
      "colorInfo": "#ac4123",
    }
  }}>
    <AppProvider />
  </ConfigProvider>

}

export default App;
